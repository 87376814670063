<template>
  <div>
    <v-card-text class="px-0 py-0">
      <v-col
        md="12"
        class="flex align-center justify-center text-center"
        v-if="loading"
      >
        <v-progress-circular v-if="loading" color="primary" indeterminate />
      </v-col>
      <v-col
        md="12"
        class="flex align-center justify-center text-center"
        v-if="!loading"
      >
        <p class="text-header" style="color: #1f6070">
          Have your partner scan the code below with their Prelude Connect App
          or enter it in the Prelude Connect Web Portal to link up your Connect
          accounts.
        </p>
      </v-col>

      <v-col
        md="12"
        class="flex align-center justify-center text-center"
        v-if="!loading"
      >
        <vue-qrcode
          v-if="codeUrl"
          v-bind:value="codeUrl"
          v-bind:errorCorrectionLevel="correctionLevel"
          v-bind:scale="qrScale"
        />
      </v-col>

      <v-col
        md="12"
        class="flex align-center justify-center text-center"
        v-if="!loading"
      >
        <p class="text-code" style="color: #1f6070">
          {{ code }}
        </p>
      </v-col>

      <v-col
        md="12"
        class="flex align-center justify-center text-center"
        v-if="!loading && !isCodeExpired && !acceptCode"
      >
        <p class="text-header" style="color: #1f6070; margin-top: 10px">
          {{ getExpirationTime }}
        </p>
      </v-col>

      <v-col
        md="12"
        class="flex align-center justify-center text-center"
        v-if="!loading && isCodeExpired && !acceptCode"
      >
        <p class="text-header" style="color: red">Expired code</p>
      </v-col>

      <v-col
        md="12"
        class="flex align-center justify-center text-center"
        v-if="!loading && acceptCode"
      >
        <p class="text-header" style="color: green">Accepted code</p>
      </v-col>

      <v-btn
        v-if="!loading && isCodeExpired"
        class="mt-8"
        color="primary"
        rounded
        block
        large
        :loading="loading"
        @click="getMyPartnerCode"
      >
        Generate new code
      </v-btn>
      <v-btn
        v-if="!loading"
        class="mt-8"
        color="primary"
        rounded
        block
        large
        to="/my-partner/"
        :loading="loading"
      >
        Cancel
      </v-btn>
    </v-card-text>
    <v-spacer />
  </div>
</template>

<script>
import VueQrcode from 'vue-qrcode';
import { api, partner } from '../../sharedPlugin';
export default {
  components: { VueQrcode },

  data() {
    return {
      valid: true,
      loading: false,
      qrValue: 'My secret value',
      correctionLevel: 'H',
      qrScale: 12,
      expirationTime: 'This code expires in 60 seconds',
      code: null,
      countDown: 60,
      isCodeExpired: false,
      acceptCode: null,
      codeUrl: null
    };
  },
  computed: {
    getExpirationTime() {
      return 'This code expires in ' + this.countDown + ' seconds';
    }
  },
  methods: {
    ...partner.mapMethods(['setLocalPartner']),
    getMyPartnerCode() {
      this.loading = true;
      api.PartnerConnect.createPartnerCode()
        .then((res) => {
          if (res && res.length > 0) {
            this.countDown = 60;
            this.isCodeExpired = false;
            this.code = res[0].code;
            this.codeUrl =
              'https://connect.preludeconnect.com/partner/code/' + res[0].code;
            this.countDownTimer();
            this.pullingPartner();
            this.loading = false;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    async onLoad() {
      api.PartnerConnect.getPartners()
        .then((res) => {
          res.forEach((partner) => {
            if (
              partner.isPartner == 1 &&
              partner.consent == 0 &&
              !this.acceptCode
            ) {
              this.acceptCode = true;
              this.$modal.show({
                text:
                  partner.name +
                  ' scanned your partner linking code and wishes to connect as your ' +
                  partner.relationship +
                  ' Do you approve it ?',
                buttons: [
                  {
                    text: 'Yes',
                    color: 'primary white--text',
                    onAction: () => {
                      var _partner = partner;
                      _partner['noShow'] = true;

                      this.setLocalPartner(_partner);
                      this.$router.push(`/my-partner/accept/${partner.id}`);
                    }
                  },
                  {
                    text: 'No',
                    color: 'red white--text',
                    onAction: async () => {
                      this.loading = false;
                      await api.PartnerConnect.unlinkPartner(
                        partner.patientId,
                        partner.isPartner
                      )
                        .then((res) => {
                          this.setLocalPartner(null);
                          this.$router.push('/my-partner');
                        })
                        .catch(() => {
                          this.$store.commit('showSnackbar', {
                            message: 'Unable to unlink partner',
                            color: 'red'
                          });
                        })
                        .finally(() => (this.loading = false));
                    }
                  }
                ]
              });
            }
          });
        })
        .catch((err) => {})
        .finally(() => ({}));
    },
    countDownTimer() {
      if (this.countDown > 0 && !this.acceptCode) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        if (!this.acceptCode) {
          this.isCodeExpired = true;
        }
      }
    },
    pullingPartner() {
      if (this.countDown > 0 && !this.acceptCode) {
        setTimeout(() => {
          this.onLoad();
          this.pullingPartner();
        }, 2500);
      }
    }
  },
  mounted() {
    this.getMyPartnerCode();
  }
};
</script>

<style scoped>
.theme--primary.v-card {
  color: '#1F6070';
}
.text-header {
  font-size: 22px;
  color: '#1F6070';
}

.text-code {
  font-size: 38px;
  color: '#1F6070';
  font-weight: bold;
}
</style>
